// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-01-2021-a-new-look-for-your-watch-tsx": () => import("./../../../src/pages/01-2021/a-new-look-for-your-watch.tsx" /* webpackChunkName: "component---src-pages-01-2021-a-new-look-for-your-watch-tsx" */),
  "component---src-pages-01-2021-click-and-reserve-tsx": () => import("./../../../src/pages/01-2021/click-and-reserve.tsx" /* webpackChunkName: "component---src-pages-01-2021-click-and-reserve-tsx" */),
  "component---src-pages-01-2021-cs-1-2-tsx": () => import("./../../../src/pages/01-2021/cs-1-2.tsx" /* webpackChunkName: "component---src-pages-01-2021-cs-1-2-tsx" */),
  "component---src-pages-01-2021-editorial-tsx": () => import("./../../../src/pages/01-2021/editorial.tsx" /* webpackChunkName: "component---src-pages-01-2021-editorial-tsx" */),
  "component---src-pages-01-2021-index-tsx": () => import("./../../../src/pages/01-2021/index.tsx" /* webpackChunkName: "component---src-pages-01-2021-index-tsx" */),
  "component---src-pages-01-2021-new-domains-opening-tsx": () => import("./../../../src/pages/01-2021/new-domains-opening.tsx" /* webpackChunkName: "component---src-pages-01-2021-new-domains-opening-tsx" */),
  "component---src-pages-01-2021-new-features-tsx": () => import("./../../../src/pages/01-2021/new-features.tsx" /* webpackChunkName: "component---src-pages-01-2021-new-features-tsx" */),
  "component---src-pages-01-2021-new-header-menu-tsx": () => import("./../../../src/pages/01-2021/new-header-menu.tsx" /* webpackChunkName: "component---src-pages-01-2021-new-header-menu-tsx" */),
  "component---src-pages-01-2021-online-live-appointment-tsx": () => import("./../../../src/pages/01-2021/online-live-appointment.tsx" /* webpackChunkName: "component---src-pages-01-2021-online-live-appointment-tsx" */),
  "component---src-pages-02-2021-addition-of-new-languages-tsx": () => import("./../../../src/pages/02-2021/addition-of-new-languages.tsx" /* webpackChunkName: "component---src-pages-02-2021-addition-of-new-languages-tsx" */),
  "component---src-pages-02-2021-collectors-corner-tsx": () => import("./../../../src/pages/02-2021/collectors-corner.tsx" /* webpackChunkName: "component---src-pages-02-2021-collectors-corner-tsx" */),
  "component---src-pages-02-2021-global-site-performance-tsx": () => import("./../../../src/pages/02-2021/global-site-performance.tsx" /* webpackChunkName: "component---src-pages-02-2021-global-site-performance-tsx" */),
  "component---src-pages-02-2021-index-tsx": () => import("./../../../src/pages/02-2021/index.tsx" /* webpackChunkName: "component---src-pages-02-2021-index-tsx" */),
  "component---src-pages-02-2021-new-markets-open-click-and-reserve-tsx": () => import("./../../../src/pages/02-2021/new-markets-open-click-and-reserve.tsx" /* webpackChunkName: "component---src-pages-02-2021-new-markets-open-click-and-reserve-tsx" */),
  "component---src-pages-02-2021-new-our-collection-page-tsx": () => import("./../../../src/pages/02-2021/new-our-collection-page.tsx" /* webpackChunkName: "component---src-pages-02-2021-new-our-collection-page-tsx" */),
  "component---src-pages-02-2021-online-exclusive-products-tsx": () => import("./../../../src/pages/02-2021/online-exclusive-products.tsx" /* webpackChunkName: "component---src-pages-02-2021-online-exclusive-products-tsx" */),
  "component---src-pages-02-2021-opening-of-a-new-e-store-tsx": () => import("./../../../src/pages/02-2021/opening-of-a-new-e-store.tsx" /* webpackChunkName: "component---src-pages-02-2021-opening-of-a-new-e-store-tsx" */),
  "component---src-pages-02-2021-preorder-tsx": () => import("./../../../src/pages/02-2021/preorder.tsx" /* webpackChunkName: "component---src-pages-02-2021-preorder-tsx" */),
  "component---src-pages-02-2021-straps-project-tsx": () => import("./../../../src/pages/02-2021/straps-project.tsx" /* webpackChunkName: "component---src-pages-02-2021-straps-project-tsx" */),
  "component---src-pages-02-2021-straps-sales-tsx": () => import("./../../../src/pages/02-2021/straps-sales.tsx" /* webpackChunkName: "component---src-pages-02-2021-straps-sales-tsx" */),
  "component---src-pages-02-2021-user-experience-ux-improvements-tsx": () => import("./../../../src/pages/02-2021/user-experience-ux-improvements.tsx" /* webpackChunkName: "component---src-pages-02-2021-user-experience-ux-improvements-tsx" */),
  "component---src-pages-02-2021-user-guide-page-tsx": () => import("./../../../src/pages/02-2021/user-guide-page.tsx" /* webpackChunkName: "component---src-pages-02-2021-user-guide-page-tsx" */),
  "component---src-pages-02-2021-wiki-website-tsx": () => import("./../../../src/pages/02-2021/wiki-website.tsx" /* webpackChunkName: "component---src-pages-02-2021-wiki-website-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

